<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="6">
        <h1 class="mt-10">Estudio de mercado</h1>
      </v-col>
      <v-col cols="6" class="mt-10" v-if="this.formatosId">
        <CheckButtons
            :tenderId="this.tenderId"
            :formatosId="this.formatosId"
        />
      </v-col>
      <v-col cols="12">
        <hr class="">
      </v-col>

      <v-col cols="12">
        <v-tabs
            background-color="primary"
            dark
        >
          <v-tab href="#form">
            Editar Estudio
          </v-tab>
          <v-tab-item value="form">
            <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Descripcion</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Descripcion</h1>
                        <trumbowyg
                            v-model="data.description"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Descripcion"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Requerimientos</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Requerimientos
                        </h1>
                        <trumbowyg
                            v-model="data.requirements"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Requerimientos"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Especificaciones Tecnicas</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Especificaciones Tecnicas
                        </h1>
                        <trumbowyg
                            v-model="data.technicalSpecifications"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Especificaciones Tecnicas"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Proovedor entrega</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Proovedor entrega
                        </h1>
                        <trumbowyg
                            v-model="data.supplierDelivery"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Proovedor entrega"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Condiciones compra</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Condiciones compra
                        </h1>
                        <trumbowyg
                            v-model="data.conditionsPurchase"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Condiciones compra"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Garantia</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Garantia
                        </h1>
                        <trumbowyg
                            v-model="data.warranty"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Garantia"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Anticipo</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Anticipo
                        </h1>
                        <trumbowyg
                            v-model="data.advanced"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Anticipo"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Normas</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Normas
                        </h1>
                        <trumbowyg
                            v-model="data.rules"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Normas"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Vigencia de precios</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Vigencia de precios
                        </h1>
                        <trumbowyg
                            v-model="data.priceValidity"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Vigencia de precios"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Forma de pago</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Forma de pago
                        </h1>
                        <trumbowyg
                            v-model="data.paymentFormat"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Forma de pago"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                </v-expansion-panels>
                <v-col cols="6" class="mt-6">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="mt-6">
                  <v-btn type="button" @click="generateData" color="primary" style="width: 200px">
                    Generar archivo
                  </v-btn>
                </v-col>
                <v-row class="mt-5">
                  <v-col cols="3">
                    <v-btn type="button" v-if="documentGenerateStatus" @click="downloadFileServer" color="primary"
                           style="width: 200px">
                      Descargar archivo
                    </v-btn>
                  </v-col>
                  <v-col cols="12" v-if="documentGenerateStatus">
                    <h1 class="mt-4 mx-2 ">Visualización de documento
                    </h1>
                    <trumbowyg
                        v-model="documentGenerate"
                        disabled="disabled"
                        name="body"
                        :config="configs.advanced"
                        placeholder="Visualización de documento"
                        class="form-control"
                    ></trumbowyg>
                  </v-col>
                </v-row>
              </v-row>

            </v-form>
          </v-tab-item>


        </v-tabs>
      </v-col>


    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import CheckButtons from "../../components/CheckButtons";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'

export default {
  name: "DetailMarketStudy",
  data() {
    return {
      overlay: false,
      tenderId: null,
      externalId: null,
      documentComplete: null,
      documentGenerate: null,
      documentGenerateStatus: false,
      id: null,
      formatosId: null,
      data: {
        description: "",
        requirements: "",
        technicalSpecifications: "",
        supplierDelivery: "",
        conditionsPurchase: "",
        warranty: "",
        advanced: "",
        paymentFormat: "",
        priceValidity: "",
        rules: "",
      },
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
    };
  },
  components: {
    CheckButtons,
    Overlay,
    Trumbowyg
  },
  async created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.id = parseInt(this.$route.params.marketId);
    await this.findMarketOne();
  },
  methods: {
    ...mapActions('marketStudies', ['updateMarketStudies', 'findOneMarket', 'generateHtmlFormat', 'downloadFile']),
    ...mapActions('tenders', ['findOneTenders']),

    async findMarketOne() {
      const response = await this.findOneMarket({id: this.id, tenderId: this.tenderId});
      if (response) {
        this.documentComplete = response.documentoCompleto
        this.data.formatData = response.formatoData;
        this.data.description = response.descripcion;
        this.data.requirements = response.requerimientos;
        this.data.technicalSpecifications = response.especificacionesTecnicas;
        this.data.supplierDelivery = response.proveedorEntrega;
        this.data.conditionsPurchase = response.condicionesCompra;
        this.data.warranty = response.garantia;
        this.data.advanced = response.anticipo;
        this.data.paymentFormat = response.formaPago;
        this.data.rules = response.normas;
        this.data.priceValidity = response.vigenciaPago;
        this.formatosId = response.formatosId;
        const responseExternal = await this.findOneTenders(response.licitacionesId);
        if (responseExternal) {
          this.externalId = responseExternal.claveLicitacion;
        } else {
          this.externalId = null;
        }
      } else {
        await this.$router.push(`/licitaciones_documentos/${this.tenderId}`)
      }
    },
    async validateRequired() {
      if (!this.data.request) {
        this.sweetAlertWarning('Campo requerimiento necesario');
        return false
      }
      if (!this.data.documentation) {
        this.sweetAlertWarning('Campo Documentación requerido');
        return false
      }
      if (!this.data.specificationsService) {
        this.sweetAlertWarning('Campo especificacions de servicio requerido');
        return false
      }
      if (!this.data.trainingRequired) {
        this.sweetAlertWarning('Campo Capacitaciones  requerido');
        return false
      }
      if (!this.data.authorizations) {
        this.sweetAlertWarning('Campo autorizaciones  requerido');
        return false
      }
      if (!this.data.requirements) {
        this.sweetAlertWarning('Campo requisitos  requerido');
        return false
      }
      if (!this.data.responsibility) {
        this.sweetAlertWarning('Campo responsabilidad  requerido');
        return false
      }
      if (!this.data.conditionsPurchase) {
        this.sweetAlertWarning('Campo condiciones de compra  requerido');
        return false
      }
      return true

    },
    async submitForm() {

      this.overlay = true;
      const response = await this.updateMarketStudies({id: this.id, data: this.data});
      if (!response.error) {
        await this.findMarketOne();
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async generateData() {
      this.overlay = true;
      const response = await this.generateHtmlFormat({id: this.id});
      if (!response.error) {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
      } else {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async downloadFileServer() {
      this.overlay = true;
      await this.downloadFile({id: this.id, externalId: this.externalId});
      this.overlay = false;
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
